/* eslint-disable import/no-extraneous-dependencies */
import {
  ListInfectedAssetsPb,
  OvaBackupProviderProto,
} from 'lib/clients/grpc-imports'
import { AbstractListRequest } from '@lib/clients/list-request'
import { OvaBackupProvider } from 'blues-corejs/dist'

export interface ListInfectedAssetsFilterParams {
  ovaAccountId: string
  backupProvidersList: Array<OvaBackupProvider>
  pageSize?: number
}

export class ListInfectedAssetsFilter extends AbstractListRequest<ListInfectedAssetsPb.Request> {
  #requestParams: ListInfectedAssetsFilterParams

  constructor(requestParam: ListInfectedAssetsFilterParams) {
    super()
    this.#requestParams = requestParam
  }

  createGrpcRequest(): ListInfectedAssetsPb.Request {
    const grpcRequest = new ListInfectedAssetsPb.Request()

    const filter = new ListInfectedAssetsPb.Request.Initial.Filter()

    const { ovaAccountId, backupProvidersList } = this.#requestParams

    filter.setOvaAccountId(ovaAccountId)

    if (backupProvidersList.length > 0) {
      filter.setBackupProvidersList(
        backupProvidersList.map((provider) =>
          this.#convertBackupProviderEnumToProto(provider)
        )
      )
    }

    return grpcRequest.setInitial(
      new ListInfectedAssetsPb.Request.Initial()
        .setFilter(filter)
        .setPageSize(
          this.#requestParams?.pageSize || AbstractListRequest.DEFAULT_PAGE_SIZE
        )
    )
  }

  #convertBackupProviderEnumToProto(
    backupProvider: OvaBackupProvider
  ): OvaBackupProviderProto {
    const mapping: Record<OvaBackupProvider, OvaBackupProviderProto> = {
      [OvaBackupProvider.VEEAM]: OvaBackupProviderProto.VEEAM,
      [OvaBackupProvider.COMMVAULT]: OvaBackupProviderProto.COMMVAULT,
      [OvaBackupProvider.RUBRIK]: OvaBackupProviderProto.RUBRIK,
      [OvaBackupProvider.COHESITY]: OvaBackupProviderProto.COHESITY,
      [OvaBackupProvider.VERITAS_NET_BACKUP]:
        OvaBackupProviderProto.VERITAS_NET_BACKUP,
    }

    return mapping[backupProvider]
  }
}
