/* eslint-disable css-modules/no-undef-class */
import Stack from '@mui/material/Stack'
import React, { useEffect } from 'react'
import { WidgetHeader } from '../shared'
import { getAccountSummaryColumnsDefinition } from './account-summary-columns-definition'
import {
  useAccountSummaryStoreActions,
  useAccountSummaryStoreData,
  useIsAccountsDataLoading,
} from './account-summary-store'
import { Container } from './styles'
import styles from './account-summary.module.css'
import { VirtualTable } from '@components/table/table-infinite-scroll-memory'
import { T_QUERY } from '@lib/constants'
import { getViewsFromConfig } from '@features/DashboardV4'
import { DEFAULT_VIEWS } from '@features/DashboardV4/management-view'
import { useTabsStoreActions } from '@features/DashboardV4/management-view/tabs-store'
import { useFetchListOvaAccounts } from '@lib/hooks/ova-accounts'
import { useRouter } from 'next/router'
import { createTemporaryTab } from './create-temporary-tab'

/**
 * Account Summary widget
 *
 * [Link to Figma](https://www.figma.com/file/X1nP5ZMRru7EKB49A2Itlu/Elastio-after-Re%3Ainvent?type=design&node-id=19346-168977&mode=design&t=L5Cu3FN0DuX2MF4B-4)
 */

export default function AccountSummary() {
  const { fetchAccountsSummary, resetStore } = useAccountSummaryStoreActions()
  const { setTemporaryTab } = useTabsStoreActions()
  const router = useRouter()

  const accountsData = useAccountSummaryStoreData()

  const sortedByInfectedStatusAccounts = accountsData.sort(
    (a, b) => b.infectedServersCount - a.infectedServersCount
  )

  const { ovaAccountsList } = useFetchListOvaAccounts()

  const isLoading = useIsAccountsDataLoading()

  const handleAccountClick = (accountId: string): void => {
    setTemporaryTab(createTemporaryTab(ovaAccountsList, accountId))

    const href = `?${T_QUERY}=${
      [...DEFAULT_VIEWS, ...getViewsFromConfig()].length
    }`

    router.push(href)
  }

  useEffect(() => {
    fetchAccountsSummary()

    return () => {
      resetStore()
    }
  }, [router.query.t])

  return (
    <Container className={styles.root}>
      <Stack spacing={1}>
        <WidgetHeader widgetTitle="Account Summary" />
        <VirtualTable
          className={styles['account-summary-table']}
          componentsProps={{
            tableProps: {
              size: 'small',
            },
          }}
          config={{
            data: sortedByInfectedStatusAccounts,
            columns: getAccountSummaryColumnsDefinition(handleAccountClick),
            state: {
              isLoading,
            },
          }}
          emptyPlaceholderText="No accounts found"
        />
      </Stack>
    </Container>
  )
}
