/* eslint-disable import/no-extraneous-dependencies */
import {
  CellWithLink,
  createColumn,
  createDisplayColumn,
  StatusCircle,
} from '@components/table'
import { OvaInfectedServerResponse } from '@lib/clients/ova-statistics/types'
import { PageHelper } from '@lib/helpers'
import { OvaBackup, OvaServer } from 'blues-corejs/dist'
import { ThreatKind } from 'blues-corejs/dist/models/threats/types'
import React from 'react'
import {
  FirstDetectedCell,
  LatestCleanRecoveryPoint,
  LatestInfectedRecoveryPoint,
} from './cells'

const buildAssetPageUrl = PageHelper.buildAssetPageUrl

function formatThreatKindsDescription(threatKinds: Array<ThreatKind>) {
  if (threatKinds.length === 0) {
    return ''
  }

  const parsedKinds = threatKinds.map((kind) => {
    if (kind === ThreatKind.MALWARE) {
      return 'Malware'
    }

    if (kind === ThreatKind.RANSOMWARE) {
      return 'Ransomware'
    }

    return ''
  })

  return `${parsedKinds.join(' & ')} found`
}

const MAX_ROW_HEIGHT = 36

export function getInfectedServersColumnsDefinition() {
  return [
    createDisplayColumn<OvaInfectedServerResponse>({
      header: '',
      id: 'status',
      enableSorting: false,
      meta: {
        width: '40',
        height: MAX_ROW_HEIGHT,
      },
      cell: () => <StatusCircle isHealthy={false} />,
    }),
    createColumn<OvaInfectedServerResponse>('server', {
      header: 'Server',
      enableSorting: false,
      cell: ({ getValue }) => {
        const server = getValue() as unknown as OvaServer

        const serverPageUrl = buildAssetPageUrl(server.id)

        return <CellWithLink href={serverPageUrl}>{server.name}</CellWithLink>
      },
    }),
    createColumn<OvaInfectedServerResponse>('threatsKindList', {
      header: 'Threats',
      enableSorting: false,
      meta: {
        height: MAX_ROW_HEIGHT,
      },
      cell: ({ getValue }) => {
        const threatsKinds = getValue() as unknown as Array<ThreatKind>

        return formatThreatKindsDescription(threatsKinds)
      },
    }),
    createColumn<OvaInfectedServerResponse>('infectedFiles', {
      header: 'Infected files',
      enableSorting: false,
      meta: {
        textAlign: 'right',
        height: MAX_ROW_HEIGHT,
      },
      cell: ({ getValue }) => {
        const infectedFiles = getValue() as unknown as number

        return infectedFiles
      },
    }),
    createColumn<OvaInfectedServerResponse>('lastInfectedBackup', {
      header: 'Latest Infected Recovery Point ',
      enableSorting: false,
      meta: {
        textAlign: 'right',
        height: MAX_ROW_HEIGHT,
      },
      cell: ({ getValue }) => {
        const latestInfectedRp = getValue() as unknown as OvaBackup | undefined

        if (!latestInfectedRp) {
          return null
        }

        return <LatestInfectedRecoveryPoint backup={latestInfectedRp} />
      },
    }),
    createColumn<OvaInfectedServerResponse>('firstDetectedAt', {
      header: 'First Detected',
      enableSorting: false,
      meta: {
        textAlign: 'right',
        height: MAX_ROW_HEIGHT,
      },
      cell: ({ getValue }) => {
        const firstDetected = getValue() as unknown as Date

        return <FirstDetectedCell firstDetected={firstDetected} />
      },
    }),
    createColumn<OvaInfectedServerResponse>('lastCleanBackup', {
      header: 'Latest  Clean Recovery Point',
      enableSorting: false,
      meta: {
        textAlign: 'right',
        height: MAX_ROW_HEIGHT,
      },
      cell: ({ getValue }) => {
        const latestCleanRp = getValue() as unknown as OvaBackup | undefined

        if (!latestCleanRp) {
          return null
        }

        return <LatestCleanRecoveryPoint backup={latestCleanRp} />
      },
    }),
  ]
}
