import React, { useMemo } from 'react'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { RansomwareIcon } from '../icons/ransomware-icon'
import { RansomwareFoundLabel, WidgetContainer } from './styles'
import { useGetOvaServerPossibleFilters } from '@lib/zustand'
import { useToolbarFiltersActions } from '@components-complex/dashboard-pages-v3/assets-with-threats/ova-servers-with-threats/toolbar/toolbar-filters-store'
import { useRouter } from 'next/router'
import { PagePathConstant } from '@lib/constants'
import { useTabsStoreActions } from '@components-complex/dashboard-pages-v3/assets-with-threats/tabs-store'
import { OvaServerThreatsQuery } from '@components-complex/dashboard-pages-v3/assets-with-threats/ova-servers-with-threats/toolbar/types'

interface Props {
  isLoading: boolean
  ransomwareCount?: number
}

function computeRansomwareIconColor(ransomwareCount?: number) {
  if (ransomwareCount === undefined) {
    return 'var(--grey500)'
  }

  return ransomwareCount > 0 ? 'var(--red400)' : 'var(--green500)'
}

function computeRansomwareLabelColor(ransomwareCount?: number) {
  if (ransomwareCount === undefined || ransomwareCount === 0) {
    return 'var(--grey900)'
  }

  return 'var(--red500)'
}

function RansomwareFoundSkeleton() {
  return (
    <WidgetContainer
      cursor="default"
      width="100%"
      data-testid="ransomware-found-skeleton"
    >
      <Skeleton>
        <RansomwareIcon />
      </Skeleton>
      <Skeleton>
        <RansomwareFoundLabel ransomwareLabelColor="red">
          25
        </RansomwareFoundLabel>
      </Skeleton>
      <Skeleton>
        <Typography fontSize="16px">Ransomware found</Typography>
      </Skeleton>
    </WidgetContainer>
  )
}
export function RansomwareFound({ isLoading, ransomwareCount }: Props) {
  const { ransomwareThreatNamesList } = useGetOvaServerPossibleFilters()
  const { setServersTabActive } = useTabsStoreActions()

  const router = useRouter()

  const { setRansomwareThreatNames } = useToolbarFiltersActions()

  const widgetCursor = useMemo(
    () => (ransomwareThreatNamesList.size === 0 ? 'default' : 'pointer'),
    [ransomwareThreatNamesList.size]
  )

  if (isLoading) {
    return <RansomwareFoundSkeleton />
  }

  const ransomwareIconColor = computeRansomwareIconColor(ransomwareCount)
  const ransomwareLabelColor = computeRansomwareLabelColor(ransomwareCount)

  const handleWidgetClick = () => {
    if (ransomwareThreatNamesList.size === 0) {
      return
    }

    setServersTabActive()
    setRansomwareThreatNames(Array.from(ransomwareThreatNamesList))
    router.push(
      `${PagePathConstant.ASSETS_WITH_THREATS_OVA_SERVERS}&${OvaServerThreatsQuery.ransomware}=all`
    )
  }

  return (
    <WidgetContainer onClick={handleWidgetClick} cursor={widgetCursor}>
      <RansomwareIcon fill={ransomwareIconColor} />
      <RansomwareFoundLabel ransomwareLabelColor={ransomwareLabelColor}>
        {ransomwareCount ?? 0}
      </RansomwareFoundLabel>
      <Typography fontSize="16px">Ransomware found</Typography>
    </WidgetContainer>
  )
}
