import React from 'react'
import { Box, Skeleton, Typography } from '@mui/material'
import NumHelper from '@lib/helpers/num.helper'
import { useRouter } from 'next/router'
import { PagePathConstant } from '@lib/constants'

interface Props {
  infectedFilesCount: number
  isLoading: boolean
}

const Container = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter()

  const handleWidgetClick = () => {
    router.push(PagePathConstant.OVA_ASSETS_ACTIVE_THREATS)
  }
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      flexBasis="50%"
      style={{ cursor: 'pointer' }}
      onClick={handleWidgetClick}
    >
      {children}
    </Box>
  )
}

function InfectedFilesSkeleton() {
  return (
    <Container>
      <Skeleton>
        <Typography fontWeight="600" fontSize="22px">
          225 M
        </Typography>
      </Skeleton>
      <Skeleton>
        <Typography fontWeight="400" fontSize="16px">
          Infected files
        </Typography>
      </Skeleton>
    </Container>
  )
}

export function InfectedFiles({ infectedFilesCount, isLoading }: Props) {
  if (isLoading) {
    return <InfectedFilesSkeleton />
  }

  const infectedFilesCountFormatted =
    NumHelper.formatLargeNumber(infectedFilesCount)

  const infectedFilesLabelColor =
    infectedFilesCount > 0 ? 'var(--red500)' : 'var(--grey900)'

  return (
    <Container>
      <Typography
        fontWeight="600"
        fontSize="22px"
        color={infectedFilesLabelColor}
      >
        {infectedFilesCountFormatted}
      </Typography>
      <Typography fontWeight="400" fontSize="16px">
        Infected files
      </Typography>
    </Container>
  )
}
