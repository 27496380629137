import React, { useMemo } from 'react'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { MalwareIcon } from '../icons/malware-icon'
import { MalwareFoundLabel, WidgetContainer } from './styles'
import { useGetOvaServerPossibleFilters } from '@lib/zustand'
import { useRouter } from 'next/router'
import { useToolbarFiltersActions } from '@components-complex/dashboard-pages-v3/assets-with-threats/ova-servers-with-threats/toolbar/toolbar-filters-store'
import { PagePathConstant } from '@lib/constants'
import { useTabsStoreActions } from '@components-complex/dashboard-pages-v3/assets-with-threats/tabs-store'
import { OvaServerThreatsQuery } from '@components-complex/dashboard-pages-v3/assets-with-threats/ova-servers-with-threats/toolbar/types'

interface Props {
  isLoading: boolean
  malwareCount?: number
}

function computeMalwareIconColor(malwareCount?: number) {
  if (malwareCount === undefined) {
    return 'var(--grey500)'
  }

  return malwareCount > 0 ? 'var(--red400)' : 'var(--green500)'
}

function computeMalwareLabelColor(malwareCount?: number) {
  if (malwareCount === undefined || malwareCount === 0) {
    return 'var(--grey900)'
  }

  return 'var(--red500)'
}

function MalwareFoundSkeleton() {
  return (
    <WidgetContainer cursor="default" data-testid="malware-found-skeleton">
      <Skeleton>
        <MalwareIcon />
      </Skeleton>
      <Skeleton>
        <MalwareFoundLabel ransomwareLabelColor="red">6</MalwareFoundLabel>
      </Skeleton>
      <Skeleton>
        <Typography fontSize="16px">Malware found</Typography>
      </Skeleton>
    </WidgetContainer>
  )
}
export function MalwareFound({ isLoading, malwareCount }: Props) {
  const { malwareThreatNamesList } = useGetOvaServerPossibleFilters()
  const { setServersTabActive } = useTabsStoreActions()

  const router = useRouter()

  const { setMalwareThreatNames } = useToolbarFiltersActions()

  const widgetCursor = useMemo(
    () => (malwareThreatNamesList.size === 0 ? 'default' : 'pointer'),
    [malwareThreatNamesList.size]
  )

  if (isLoading) {
    return <MalwareFoundSkeleton />
  }

  const malwareIconColor = computeMalwareIconColor(malwareCount)
  const malwareLabelColor = computeMalwareLabelColor(malwareCount)

  const handleWidgetClick = () => {
    if (malwareThreatNamesList.size === 0) {
      return
    }

    setServersTabActive()
    setMalwareThreatNames(Array.from(malwareThreatNamesList))
    router.push(
      `${PagePathConstant.ASSETS_WITH_THREATS_OVA_SERVERS}&${OvaServerThreatsQuery.malware}=all`
    )
  }

  return (
    <WidgetContainer cursor={widgetCursor} onClick={handleWidgetClick}>
      <MalwareIcon fill={malwareIconColor} />
      <MalwareFoundLabel ransomwareLabelColor={malwareLabelColor}>
        {malwareCount ?? 0}
      </MalwareFoundLabel>
      <Typography fontSize="16px">Malware found</Typography>
    </WidgetContainer>
  )
}
