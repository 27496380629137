import React from 'react'
import { Box, Skeleton, Typography } from '@mui/material'
import { useRouter } from 'next/router'
import { PagePathConstant } from '@lib/constants'

interface Props {
  totalServersCount: number
  isLoading: boolean
}

function TotalServersSkeleton() {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap="14px"
      justifyContent="center"
      marginTop="10px"
    >
      <Skeleton>
        <Typography fontWeight="600" fontSize="22px">
          154
        </Typography>
      </Skeleton>
      <Skeleton>
        <Typography fontWeight="400" fontSize="16px">
          Servers
        </Typography>
      </Skeleton>
    </Box>
  )
}

export function TotalServers({ totalServersCount, isLoading }: Props) {
  const router = useRouter()

  const handleWidgetClick = () =>
    router.push(PagePathConstant.OVA_SERVER_INVENTORY_TABLE)

  if (isLoading) {
    return <TotalServersSkeleton />
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      gap="14px"
      justifyContent="center"
      marginTop="10px"
      sx={{ cursor: 'pointer' }}
      onClick={handleWidgetClick}
    >
      <Typography fontWeight="600" fontSize="22px">
        {totalServersCount}
      </Typography>
      <Typography fontWeight="400" fontSize="16px">
        Servers
      </Typography>
    </Box>
  )
}
